<template>
  <div
    class="main"
    v-if="order"
  >
    <div class="order-bg" :style="{ background: color_data.specialColor }"></div>
    <div class="order-status">
      <div class="t fw500">
        <template v-if="(order.status == -3 || order.status == 0) && order.is_pay == 0">待付款</template>
        <!-- <template v-if="order.status == -4">凭证待审核</template> -->
        <template v-else-if="order.status == 0">待发货</template>
        <template v-else-if="order.status == 1">待收货</template>
        <template v-else-if="order.status == 2">待评价</template>
        <template v-else-if="order.status == 3">已完成</template>
        <template v-else-if="order.status == -1">已退款</template>
        <template v-else-if="order.status == -2">已关闭</template>
      </div>
      <template v-if="order.status == -3 || order.status == 0">
        <div class="b">下单时间 {{ order.create_time }}</div>
      </template>
      <template v-if="order.status == -4">
        <div class="b">下单时间 {{ order.create_time }}</div>
      </template>
      <template v-else-if="order.status == 1">
        <div class="b">支付时间 {{ order.pay_time }}</div>
      </template>
      <template v-else-if="order.status == 2">
        <div class="b">支付时间 {{ order.pay_time }}</div>
      </template>
      <template v-else-if="order.status == 3">
        <div class="b">支付时间 {{ order.pay_time }}</div>
      </template>
      <template v-else-if="order.status == 4">
        <div class="b">支付时间 {{ order.pay_time }}</div>
      </template>
      <template v-else-if="order.status == -1">
        <div class="b">售后已完成</div>
      </template>
      <template v-else-if="order.status == -2">
        <div class="b">关闭时间 {{ order.cancel_time }}</div>
      </template>
      <!-- <div class="b">请您9月17日前完成支付，避免违约。</div> -->
    </div>
    <div class="express-view">
      <div class="express-view-top flex align-items-c ft12">
        <div
          class="flex align-items-c"
          style="width: 100%;"
        >
          <div class="ft14 fw500 color-black">{{ order.order_sn }}</div>
          <div
            class="copy flex-one"
            @click="copyAction(order.order_sn)"
          >复制</div>
          <div class="color-gray">{{ order.create_time }}</div>
        </div>
      </div>
      <div
        class="express-view-center"
        v-if="order.user_address && order.delivery_type == 1"
      >
        <div class="flex justify-b ft14 fw500">
          <div class="address-name flex-one">{{ order.real_name }}</div>
          <div class="address-phone">{{ order.user_phone }}</div>
        </div>
        <div class="flex justify-b ft12 mt8">
          <div class="address flex-one">{{ order.user_address }}</div>
        </div>
      </div>
      <div
        class="express-view-bottom flex justify-b"
        v-if="order.user_address && order.delivery_type == 1 && order.delivery_insured_price"
      >
        <div class="flex align-items-c">
          <div class="color-black">{{ order.delivery_insured_price ? '已保价' : '未保价' }}</div>
          <div
            class="color-gray ml20"
            v-if="order.delivery_insured_price"
          >保价金额 <span class="color-black fw500">{{ formatPriceNumber(Number(order.delivery_insured_price), 2) }}</span></div>
        </div>
      </div>
    </div>
    <div class="common-title">货品信息</div>
    <div class="product-view common-view">
      <div
        class="item flex align-items-c"
        v-for="(m, i) in order.orderProduct"
        :key="`product${i}`"
      >
        <div class="img">
          <img
            :src="m.cart_info.product.image + '!120a'"
            alt=""
          >
        </div>
        <div class="desc">
          <div class="name fw500 ft12"><span v-if="m.cart_info.product.lot" class="mr5">LOT{{ m.cart_info.product.lot }} </span>{{ m.cart_info.product.store_name }}</div>
          <div class="price">
            <span>落槌价 <b>{{ formatPriceNumber(Number(m.product_price), 2) }}</b></span>
            <span>佣金 <b>{{ formatPriceNumber(Number(m.commission_price), 2) }}</b></span>
            <span>
              <template v-if="order.delivery_type == 1">
                <template v-if="m.cart_info.product.postage_type == 2">运费 {{ formatPriceNumber(Number(m.cart_info.product.postage_price), 2) }}</template>
                <template v-else-if="m.cart_info.product.postage_type == 3">包邮</template>
                <template v-else-if="m.cart_info.product.postage_type == 4">到付</template>
              </template>
              <template v-else-if="order.delivery_type == 2">自提</template>
              <template v-else-if="order.delivery_type == 3">委托自提</template>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="logistics"
      v-if="order.delivery_name && order.delivery_id"
    >
      <div class="common-title">物流信息</div>
      <LogisticsInfo
        :express="express"
        :name="order.delivery_name"
        :id="order.delivery_id"
      />
    </div>
    <div class="common-title">抵扣金额</div>
    <div class="deduction common-view ft14 color-black">
      <div
        class="item flex align-items-c"
        v-if="order.pay_price - Number(order.use_entrust_wallet_price)"
      >
        <div class="l">余额</div>
        <div class="flex-one c ft12"></div>
        <div class="r">-{{ formatPriceNumber(order.pay_price - Number(order.use_entrust_wallet_price), 2) }}{{ merchantInfo.fee_type_text }}</div>
      </div>
      <div
        class="item flex align-items-c"
        v-if="Number(order.use_entrust_wallet_price)"
      >
        <div class="l">委托方账户</div>
        <div class="flex-one c ft12"></div>
        <div class="r">-{{ formatPriceNumber(Number(order.use_entrust_wallet_price), 2) }}{{ merchantInfo.fee_type_text }}</div>
      </div>
    </div>
    <template v-if="order.receipt">
      <div class="common-title">发票信息</div>
      <div
        class="deduction common-view ft14 color-black"
        @click="showReceiptAction(order.receipt.receipt_image)"
      >
        <div class="item flex align-items-c">
          <div class="l">{{ order.receipt.receipt_header_type == 1 ? '个人' : '企业' }}-{{ order.receipt.receipt_type == 1 ? '普通发票' : '电子发票' }}</div>
          <div class="flex-one c ft12"></div>
          <div
            v-if="order.receipt.status == 0"
            class="r ft12"
            style="font-weight: 400;color: #000;"
          >未开票</div>
          <div
            v-else-if="order.receipt.status == 1"
            class="r ft12"
            style="font-weight: 400;color: #347FF4;"
          >已开票</div>
          <div
            v-else-if="order.receipt.status == 2"
            class="r ft12"
            style="font-weight: 400;color: #347FF4;"
          >已寄出</div>
        </div>
      </div>
    </template>
    <div class="common-title">支付信息</div>
    <div class="deduction common-view ft14 color-black">
      <div class="item flex align-items-c">
        <div class="l">货品总价</div>
        <div class="flex-one c ft12"></div>
        <div class="r"><template v-if="merchantInfo">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}</template>{{ formatPriceNumber(order.total_price, 2) }}</div>
      </div>
      <div
        class="item flex align-items-c"
        v-if="order.commission_price"
      >
        <div class="l">佣金</div>
        <div class="flex-one c ft12"></div>
        <div class="r"><template v-if="merchantInfo">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}</template>{{ formatPriceNumber(order.commission_price, 2) }}</div>
      </div>
      <div
        class="item flex align-items-c"
        v-if="order.total_postage"
      >
        <div class="l">运费</div>
        <div class="flex-one c ft12"></div>
        <div class="r"><template v-if="merchantInfo">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}</template>{{ formatPriceNumber(order.total_postage, 2) }}</div>
      </div>
      <div
        class="item flex align-items-c"
        v-if="order.delivery_insured_price"
      >
        <div class="l">保价</div>
        <div class="flex-one c ft12"></div>
        <div class="r"><template v-if="merchantInfo">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}</template>{{ formatPriceNumber(order.delivery_insured_price, 2) }}</div>
      </div>
      <div class="item flex align-items-c">
        <div class="l">{{ order.paid ? '实付款' : '待付款' }}</div>
        <div class="flex-one c ft12"></div>
        <div class="r"><template v-if="merchantInfo">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}</template>{{ formatPriceNumber(order.pay_price, 2) }}</div>
      </div>
      <!-- <div class="item flex align-items-c" v-if="order.paid && ">
        <div class="l">微信交易订单</div>
        <div class="flex-one c ft12"></div>
        <div class="r"></div>
      </div> -->
      <div
        class="item flex align-items-c"
        v-if="order.paid"
      >
        <div class="l">付款时间</div>
        <div class="flex-one c ft12"></div>
        <div class="r">{{ order.pay_time }}</div>
      </div>
    </div>
    <!-- <div class="common-title">支付方式</div>
    <PayTypeView v-model="payType" /> -->
    <div class="no-data flex item-flex-center">
      <div class="heng"></div>
      <div class="txt">我是有底线的</div>
      <div class="heng"></div>
    </div>
    <div
      class="bottom-btn flex align-items-c"
      v-if="(order.status == 0 || order.status == -3) && order.is_pay == 0"
    >
      <div class="l">还需支付<span
          class="fw500"
          style="color: #FB7575;"
        >{{ formatPriceNumber(order.pay_price, 2) }}</span></div>
      <div
        class="r-btn ft14 fw500"
        @click="goPayAction"
        :style="rightBtnStyle"
      >立即支付</div>
    </div>
    <div
      class="bottom-btn flex align-items-c justify-e"
      v-else-if="order.status > 0"
    >
      <!-- <div
        class="btn bor"
        v-if="(order.status == 1)"
      >签署委托发货协议</div> -->
      <div
        class="btn bor"
        v-if="(order.status == 1)"
        @click="orderReceivedAction"
        :style="rightBtnStyle"
      >确认收货</div>
      <!-- <div
        class="btn"
        v-if="(order.status == 3 || order.status == 4)"
      >收货凭证</div> -->
      <div
        class="btn"
        v-if="(order.status == 1 || order.status == 0) && !order.is_refund && order_refund"
        @click.stop="applyRefundAction"
      >申请售后</div>
      <div
        class="btn"
        v-if="order.is_refund"
        @click.stop="goRefundAction"
      >查看售后</div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Switch, ImagePreview } from "vant";
import PayTypeView from "@/components/PayType";
import { orderDetail, orderReceived, expressInfo } from "@/api/order";
import { getConfigClassKeys } from "@/api/system";
import LogisticsInfo from "./modules/LogisticsInfo.vue";
import { Toast, Dialog } from "vant";
import { formatPriceNumber, parseTime } from "@/utils/util";

export default {
  components: {
    [Switch.name]: Switch,
    PayTypeView,
    LogisticsInfo,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const id = ref("");
    const order = ref("");
    const expressType = ref(1); // 快递类型 1 快递 2 自提 3 委托
    const openInsuredPrice = ref(true); // 是否保价
    const insuredPrice = ref(0); // 保价金额
    const useBalance = ref(0); // 使用余额（元）
    const usePoints = ref(0); // 使用积分（元）
    const payType = ref("bank"); // 支付方式

    const order_refund = ref(0); // 开启退款
    onBeforeMount(() => {
      id.value = route.query.id;
      loadDetail();

      getConfigClassKeys("auction_rule_set").then((res) => {
        order_refund.value = res.data.order_refund;
      });
    });

    const loadDetail = () => {
      orderDetail(id.value)
        .then((res) => {
          order.value = res.data;
          if (res.data.delivery_name && res.data.delivery_id) {
            loadExpressInfo();
          }
        })
        .catch((message) => {
          Toast(message);
          // setTimeout(() => {
          //   router.back();
          // }, 1000);
        });
    };
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });
    // 右按钮样式
    const color_data = computed(() => {
      return store.getters.color_data
        ? store.getters.color_data
        : {};
    });
    const express = ref([]); // 物流信息
    const loadExpressInfo = () => {
      expressInfo(id.value)
        .then((res) => {
          express.value = res.data.express || [];
        })
        .catch((message) => Toast(message));
    };

    const merchantInfo = computed(() => {
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });
    // 选择快递类型
    const changeExpressTypeAction = (key) => (expressType.value = key);

    // 选择支付方式
    const changePayTypeAction = (type) => (payType.value = type);

    const goPayAction = () => {
      // 去支付
      router.push({
        path: "/pages/orderConfirm/index",
        query: { order_id: id.value },
      });
    };
    const applyRefundAction = () => {
      // 申请售后
      localStorage.setItem("refundProduct", JSON.stringify(order.value));
      router.push("/order/apply_refund");
    };

    let lock = false;
    const orderReceivedAction = (index) => {
      // 确认收货
      Dialog.confirm({
        title: "确定收到货物？",
        message:
          "为保障您的售后权益，请收到货确认无误后，在确认收货哦！确认收货后，货款将打入商家账户！",
      }).then(() => {
        if (lock) return false;
        lock = true;
        Toast.loading({
          forbidClick: true,
          duration: 0,
        });

        orderReceived(id.value)
          .then(() => {
            loadDetail();
            Toast.clear();
            Toast("操作成功");
            lock = false;
          })
          .catch((message) => {
            Toast.clear();
            Toast(message);
            lock = false;
          });
      });
    };

    const goRefundAction = () => {
      router.push("/order/refund_detail?id=" + order.value.refundInfo.refund_order_id);
    };

    const showReceiptAction = (img) => {
      if (img) {
        ImagePreview([img]);
      }
    };

    return {
      order,
      expressType,
      openInsuredPrice,
      insuredPrice,
      useBalance,
      usePoints,
      payType,
      formatPriceNumber,
      express,
      color_data,
      order_refund,
      merchantInfo,
      rightBtnStyle,

      changeExpressTypeAction,
      changePayTypeAction,
      goPayAction,
      applyRefundAction,
      orderReceivedAction,
      goRefundAction,
      showReceiptAction,
    };
  },
  methods: {
    copyAction(val) {
      this.$copyText(val).then(() => {
        Toast("复制成功");
      }, () => {
        Toast("复制失败");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  min-height: 100vh;
  background: #f5f5f5;
  box-sizing: border-box;
  padding-bottom: 164px;

  .order-bg {
    background: #003d7c;
    height: 200px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    right: 0;
  }

  .order-status {
    position: relative;
    padding: 0 32px;
    margin-top: 32px;
    z-index: 10;
    padding-bottom: 32px;

    .t {
      font-size: 36px;
      color: #fff;
    }

    .b {
      color: #fff;
      margin-top: 8px;
    }
  }

  .common-title {
    margin-top: 32px;
    padding-bottom: 12px;
    padding-top: 32px;
    font-size: 28px;
    font-weight: 500;
    padding-left: 32px;
    color: #000;
    background: #fff;
  }
}
.express-view {
  margin: 0 32px 32px 32px;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 22px 32px;
  position: relative;
  z-index: 10;

  .express-view-top {
    .copy {
      margin-left: 16px;
      color: #347ff4;
    }
  }

  .ml48 {
    margin-left: 48px;
  }

  .express-view-center {
    padding: 22px 32px;
    background: #f7f7f7;
    margin-top: 26px;
    border-radius: 8px;

    .mt8 {
      margin-top: 8px;
    }

    .address {
      color: #999;
    }
    .address-edit {
      color: #347ff4;
    }
  }

  .express-view-bottom {
    margin-top: 32px;
    margin-bottom: 10px;
    color: #666;
    font-size: 28px;
  }
}
.common-input {
  text-align: center;
  width: 218px;
  height: 64px;
  line-height: 64px;
  border: 2px solid #eeeeee;
  box-sizing: border-box;
}

.product-view {
  .item {
    border-top: 2px solid #eeeeee;
    padding: 32px 0;
    &:first-child {
      border-top: none;
    }
    .img {
      width: 128px;
      height: 128px;
      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 8px;
      }
    }

    .desc {
      flex: 1;
      margin-left: 18px;
      color: #000;

      .name {
        line-height: 35px;
        height: 70px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .price {
        margin-top: 16px;
        color: #999999;
        span {
          margin-right: 10px;
        }
      }
    }
  }
}

.deduction {
  .item {
    padding-top: 32px;

    &:first-child {
      padding-top: 22px;
    }

    &:last-child {
      padding-bottom: 32px;
    }

    .c {
      color: #999;
      margin-left: 16px;
      font-weight: 400;

      span {
        color: #347ff4;
        margin-left: 16px;
      }
    }

    .w130 {
      width: 130px;
    }

    .ml52 {
      margin-left: 52px;
    }
    .ml12 {
      margin-left: 12px;
    }
  }
}

.common-view {
  background: #fff;
  border-radius: 8px;
  padding: 0 32px;

  .l{
    color: #666;
  }

  .r{
    font-size: 28px;
  }
}
.common-pay {
  padding: 24px 32px;
  margin: 0 32px;
  border: 2px solid #eeeeee;
  box-sizing: border-box;

  .img {
    width: 48px;
    height: 48px;
    display: block;
  }

  .ml16 {
    margin-left: 16px;
    color: #666;
  }

  .upload-certificate {
    height: 200px;
    border-radius: 8px;
    background: #f7f7f7;
    border: 2px solid #eee;
    margin-top: 32px;

    img {
      width: 48px;
      height: 48px;
    }

    div {
      margin-top: 8px;
      color: #666;
    }
  }

  .bank-title {
    margin-top: 20px;
    color: #666;
  }

  .bank-list {
    color: #666;
    padding-top: 8px;

    .flex {
      padding-top: 8px;

      .r {
        color: #347ff4;
      }
    }
  }
}

.bottom-btn {
  padding-bottom: 68px;
  height: 164px;
  background: #fff;
  box-shadow: 0px -2px 0px 0px #eeeeee;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding-right: 12px;
  box-sizing: border-box;

  .l {
    padding-left: 32px;
    color: #666;
    flex: 1;
    padding-right: 32px;

    span {
      font-size: 32px;
      margin-left: 10px;
    }
  }
  .r-btn {
    height: 96px;
    width: 240px;
    text-align: center;
    line-height: 90px !important;
    background: #003d7c;
    color: #fff;
    margin-right: -12px;
    border-radius: 0 !important;
  }

  .btn {
    min-width: 160px;
    height: 60px;
    line-height: 56px;
    text-align: center;
    box-sizing: border-box;
    padding: 0 24px;
    background: #ffffff;
    border-radius: 8px;
    border: 2px solid #dddddd;
    margin-right: 16px;
    color: #333;

    &.bor {
      font-size: 24px !important;
      line-height: 52px !important;
    }
  }
}

.logistics{
  background: #fff;
}
</style>