<template>
  <div class="logistics">
    <div class="t item-align-center ft12 fw500">
      <div class="l">{{ name }}</div>
      <div class="c">{{ id }}</div>
      <div
        class="copy"
        @click="copyAction(`${name} ${id}`)"
      >复制</div>
    </div>
    <div class="content ft12 color-gray">
      <div
        class="item"
        :class="{ 'color-black': index == 0 }"
        v-for="(item, index) in express"
        :key="index"
      >
        <div class="name"><span
            v-if="item.status.indexOf('签收人') !== -1"
            style="color: #FF6600;"
          >已签收</span><span v-else>派送中</span> {{ item.time }}</div>
        <div class="txt">{{ item.status }}</div>
        <div
          class="item-bg"
          :style="{ background: (index == 0 ? '#FF6600' : '#ddd') }"
        ></div>
      </div>
      <div class="bg"></div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';

export default {
  props: {
    id: String,
    name: String,
    express: Array,
  },
  setup() {},
  methods: {
    copyAction(val) {
      this.$copyText(val).then(() => {
        Toast("复制成功");
      }, () => {
        Toast("复制失败");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.logistics {
  margin: 32px;
  background: #fff;
  padding: 32px;
  border-radius: 8px;

  .t {
    .l {
      margin-right: 8px;
    }
    .copy {
      margin-left: 16px;
      color: #347ff4;
    }
  }
  .content {
    padding: 32px 0;
    position: relative;
    max-height: 999999px;

    .item {
      padding-left: 40px;
      margin-top: 32px;
      position: relative;

      &:first-child {
        margin-top: 0;
      }

      .name {
        color: #999;
        span {
          font-size: 28px;
          font-weight: 500;
        }
      }

      .item-bg {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #ddd;
        position: absolute;
        left: 0;
        top: 14px;
        z-index: 10;
      }
    }

    .bg {
      position: absolute;
      left: 6px;
      top: 50px;
      bottom: 32px;
      width: 2px;
      background: #eee;
    }
  }
}

.txt {
  max-height: 99999px;
}
</style>